import React from "react"
import Layout from '../components/layout'
import SEO from "../components/seo"


import {Container, Row, Col} from "react-bootstrap"

const GypsumCost = () => (
  <Layout>
  <SEO
        title="Gypsum False Ceiling Cost"
        keywords={[
          "tags",
          "Pramri Interiors tages",
          "false ceiling",
          "Tips for false ceiling",
          "Interior Design",
          "Interior Design planning",
          "Gypsum vs POP",
          "Different types of False Ceiling",
          "Modular Kitchen",
          "Kitchen Design",
          "House Painting",
          "Kitchen Laminates",
          "PVC Laminates",
          "Laminates Selection",
          "Small house Design",
          "Small master bedroom design",
          "Master Bedroom design",
          "Woodwork",
          "Home Interiors",
          "tags",
          "Pramri Interiors tags",
          "false ceiling in hindi",
          "Tips for false ceiling in hindi",
          "Interior Design in hindi",
          "Interior Design planning in hindi",
          "Gypsum vs POP in hindi ",
          "Different types of False Ceiling in hindi",
          "Modular Kitchen in hindi",
          "Kitchen Design in hindi ",
          "House Painting in hindi",
          "Kitchen Laminates in hindi",
          "PVC Laminates in hindi",
          "Laminates Selection in hindi",
          "Small house Design in hindi",
          "Small master bedroom design in hindi",
          "Master Bedroom design in hindi",
          "Woodwork in hindi",
          "Home Interiors in hindi" 
        ]}
      />
  <Container>
    <h1>Gypsum board False Ceiling Installation Cost</h1>
    <Row>
<p>
    <p>
1). Gypsum Installation
2). Electrical wiring
3). Switchboard Installation
3). LED Light Installation
4). Rope/Strip Light 
5). Painting

Optional requirement.
1). Thermocol sheet
2). Fire Sprinkler 
3). Copper wiring for AC
</p>

Gypsum board installation ka jo rate hota hai 40 se leke 60 rs ke bich me ho jata hai
is rate me acchi quality ka gypsum sheet use kar sakte hain..Market me bahut sare brand ke Gypsum board
maujud hai jo ki acche quality ke hote hain... 
 
Uske baad aapko electrical ka cost alag se hota hai.. Generally aapko electrical cost light point 
pe hota hai...rate varry karta hai lekin on an average rate 300 se leke 500 ke aas pass hota hai..
agar light ka point jyada ho to rate ho kam ho jata hai..

<b>Swtich board</b>  Aap kabhi bhi Switch board lagane me kami na kare... lights ke liye proper planning kare
aur switch board ka provision uske according kare.. Uske liye bhi aapko budget ki planning karni hogi..
Approximate cost 5k- 8k aap isliye liye rakh sakte hain

Uske baad aapko LED light ke liye budget ki planning karni hogi.. Agar aap LED light Residential house ke liye 
provision karte hain to kabhi bhi 9-10 watts ki light lagaye to accha dikhta hai..
Agar Commerical complext ke liye provision karte hain to 12-15 watts ki light laga sakte hain
Jyada watts ki kam LED light lagane ke wajay kam watts ki jyada LED lights ka provision kare..
Agar aap kam watts ki light ka provision karte hain to o bhi kaphi accha dikhta hai..
Acche brand ki LED Light cost approximate 250 rs se leke 500 rs ke aas paas aat hai.. 250 rs ke aass
pass 2 watts ki LED aata hai aur 500 rs ke aas pass 10 watts ki LED aa jati hai


Ye mera personal suggestion hai.. aapka kya suggestion hai comment section me jarur bataye..

Uske baad Rope light ke liye budget ki planning karni hogi.. 
Ek baat aapko bata du.. aap apne ghar me Rope light ka bhi jarur istemal kare.. uske ghar ka look kaphi 
accha dikhne lagta hai..
Rope light aap ka cost bhi varry karta hai.. generally cost 40 rs se 80 rs ke bich varry karta hain..
waise rope light alag alag quality ka aata hai kuch ki price 100 rs + metre aata hai.. usko false ceiling
ke liye istemal karne ki jarurat nahi hai..

Ek mahatwapurn baat.. LED Lights aap kabhi bhi branded hi use kare.. uski durability jyada hoti hai..

Agar aapka ghar top floor pe hai to aap Thermocol ka istemal kar sakte hain.. lekin usse aapke false ceilin
ki cost thodi badh jati hai.. approximate cost 20 -30 rs.. ye bhi bahut sare factor pe depend karta hai..</p>

    </Row>
  </Container>
</Layout>
  )

  
export default GypsumCost